<template>
  <div>
    <div class="content">
      <div class="contentmin">
        <div class="header">
          <img
            class="headerImg"
            src="https://depot.careld.cn/static/img/logo1.5a6ac299.png"
          />
          <p class="headerTitle">健康数据管理中心</p>
        </div>
        <div class="main">
          <div class="container">
            <el-form ref="ruleForm" :model="ruleForm" label-width="50px">
              <el-form-item label="账号">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入数据中心账号"
                  style="margin-bottom: 15px"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="pwd">
                <el-input
                  type="password"
                  v-model="ruleForm.password"
                  autocomplete="off"
                  placeholder="请输入密码"
                  style="margin-bottom: 15px"
                ></el-input>
              </el-form-item>
              <el-form-item
                style="text-align: center; margin-top: 30px; margin-right: 15%"
              >
                <el-button
                  type="primary"
                  style="
                    padding: 10px 46px;
                    border-radius: 8px;
                    background: rgb(215, 134, 115);
                    border-color: rgb(215, 134, 115);
                    font-size: 15px;
                    letter-spacing: 4px;
                  "
                  @click="submitForm(ruleForm)"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import base from "../api1/config";
import base from "../../api1/config";
// import { api } from "../api1/config";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        password: "",
      },
    };
  },
  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
    window.localStorage.removeItem("Username");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("loginuser");
    window.localStorage.removeItem("alltring");
    window.localStorage.removeItem("dataList");
    window.localStorage.removeItem("dataListEror");
    window.localStorage.removeItem("alltringTwo");
    window.localStorage.removeItem("dataLisTwo");
    // window.localStorage.removeItem("arrGroupIdOne");
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    submitForm() {
      let that = this;
      if (that.ruleForm.name == "" && that.ruleForm.password == "") {
        alert("用户名密码不能为空！");
      } else {
        console.log("路径：", base.login);
        that
          .$http({
            url: base.login,
            params: that.ruleForm,
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          })
          .then((res) => {
            if (res.data.code === 200) {
              //   window.location.href="camera.vue"
              that.$router.push("./camera");
              this.$message({
                message: "登录成功！",
                type: "success",
              });
              localStorage.setItem("Username", JSON.stringify(res.data.result));
              localStorage.setItem(
                "token",
                JSON.stringify(res.data.result.token)
              );
            } else {
              that.$message.error(res.data.msg);
              console.log(res.config)
              that
                .$http({
                  url: "/too/center/weblog/save",
                  params: {
                    callback: "",
                    errorCode: res.data.code,
                    errorExplain: res.data.msg,
                    interfaceUrl: base.login,
                    menuName: "库管登录页",
                    menuUrl: window.location.href,
                    params: res.config.params,
                    source: "库管系统",
                    userId: "",
                    userName: "",
                  },
                  method: "get",
                })
                .then((res) => {
                  //验证数据是否获取到
                  if (res.data.code == 200) {
                    console.log(res, "保存");
                  } else {
                    console.log("失败");
                  }
                });
            }
          });
      }
    },
    // 回车登录
    keyDown(e) {
      //如果是回车则执行登录方法1
      if (e.keyCode == 13) {
        this.submitForm();
      }
    },
  },
};
</script>

<style lang='less' scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  width: 100%;
  height: auto;
  background-color: pink;
}

.contentmin {
  width: 100%;
  margin: 26% auto;
  height: auto;
  background-color: #ffffff;
}

.header {
  text-align: center;
}

.headerImg {
  width: 56%;
}

.headerTitle {
  color: #91aca7;
  font-size: 18px;
  line-height: 75px;
  letter-spacing: 5px;
  margin-bottom: 30px;
}

label {
  float: left;
  line-height: 30px;
}

.form-horizontal {
  margin-top: 38px;
}

.form-control {
  width: 78% !important;
  height: 30px;
  border: none;
  outline: 0;
  box-shadow: none;
  border-bottom: 1px solid #d6d6d6;
  border-radius: 0;
  background: #e8f0fe;
  font-size: 12px;
}

.btn-default {
  background: #d78673;
  font-size: 13px;
  border: none;
  border-radius: 15px;
  padding: 6px 32px;
  color: #fff;
  margin-top: 36px;
}

.form-group {
  text-align: center;
  margin-bottom: 8%;
}
.main {
  width: 90%;
  margin-left: 5%;
}
</style>