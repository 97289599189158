
import axios from '../utils/request';
const base={
    baseUrl:'https://erp.careld.cn',
    login:'/too/login/login',//登录接口
    //Token验证
    selectProduct:'/api/dicts/getDictsByDictCode',//产品管理下拉框
}

//请求方法
export const api={
    //产品管理下拉框
    getSelectProduct(params){
        return axios.post(base.selectProduct,params);
    },
}

export default base