<template>
  <div id="app" :style="{ height: clientHeight - 0 + 'px' }">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      clientHeight: document.body.clientHeight,
      isRouterAlive: true,
    };
  },
  mounted() {
    console.log(this.$route.path, "路径地址");
    if (this.$route.path == "/") {
      console.log('/');
    } else if (this.$route.path == "/") {
      console.log('/');
    } else {
      let loginUserId = JSON.parse(localStorage.getItem("token"));
      if (loginUserId === null || loginUserId === "") {
        this.$route.push("/");
      }
    }
    // window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e)); //3
    // let isOpen = localStorage.getItem("isOpen"); //1
    // let reload = localStorage.getItem("reload"); //2
    // this.timer2 = setTimeout(() => {
    //   localStorage.removeItem("reload"); //4
    // }, 2000);
    // console.log(isOpen, reload, isOpen == "open", reload != "reload", "reload");
    // if (isOpen == "open" && reload != "reload") {
    //   localStorage.removeItem("isOpen");
    //   localStorage.removeItem("reload");
    //   this.timer3 = setTimeout(() => {
    //     let open1 = localStorage.getItem("isOpen"); //7
    //     let load1 = localStorage.getItem("reload");
    //     if (open1 == "open" && load1 !== "reload") {
    //       this.$alert("网站重复打开即将跳转到登录页", {
    //         confirmButtonText: "确定",
    //         callback: (action) => {
    //           console.log("action:", action);
    //         },
    //       });
    //       this.timer4 = setTimeout(() => {
    //         window.opener = null;
    //         // location.href = "https://dev.careld.cn";
    //         this.$router.push("/");
    //         window.close();
    //         localStorage.removeItem("reload");
    //         localStorage.removeItem("isOpen"); //6
    //       }, 2000);
    //     } else {
    //       history.go(0);
    //     }
    //   }, 1000);
    // } else {
    //   setInterval(() => {
    //     localStorage.setItem("isOpen", "open"); //5
    //   }, 1000);
    // }

    // let loginUserId = JSON.parse(localStorage.getItem("token"));
    // console.log(loginUserId, "loginUserId");
    // if (loginUserId == null) {
    //   this.$router.push("/");
    // }

    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },

    beforeunloadHandler(e) {
      //根据事件进行操作进行操作
      console.log(e);
      console.log("浏览器刷新");
      localStorage.setItem("reload", "reload");
    },
  },
  destroyed() {
    //进行监听销毁
    localStorage.removeItem("isOpen");
    localStorage.removeItem("reload");
    clearTimeout(this.timer2);
    clearTimeout(this.timer3);
    clearTimeout(this.timer4);
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
};
</script>
<style lang="less" scoped>
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Family;
}
</style>
