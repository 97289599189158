import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/mobile/login.vue'
// import store from '../store'
//解决重复跳转路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'mobile',
    component: Login,
  },
  {
    path: '/camera',
    name: 'camera',
    component: () => import('../views/mobile/camera.vue'),
  },
  {
    path: '/submit',
    name: 'submit',
    component: () => import('../views/mobile/submit.vue'),
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// //全局守卫
router.beforeEach((to, from, next) => {
  next()
  console.log(to, from, next);
})

export default router
